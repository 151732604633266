







































































import merge from "../utils/merge";
import defaultComponent from "../utils/defaultComponent";
import DataContainer from "../common/DataContainer";

export default defaultComponent.apply({
  props: ["xOptions"],
  data() {
    return {
      titleHTML: "",
      contentHTML: "",
      processing: false,
      noBtnContainer: new DataContainer(),
      yesBtnContainer: new DataContainer(),
    };
  },
  watch: {
    model: {
      handler(val) {
        if (val && val.value) {
          this.updateContext();
        }
      },
      deep: true,
    },
    xOptions: {
      handler(val) {
        if (val) {
          this.options = merge.clone(this.options, val);
        }
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      for (const key in this.options.content.buttons) {
        let button = this.options.content.buttons[key];
        if (!button.ignoreDefault) {
          button = merge.clone(this.options.content.template.button, button);
        }
        this.options.content.buttons[key] = button;
      }

      this.updateContext();
    },
    updateContext() {
      switch (typeof this.options.content.title.html) {
        case "function":
          this.titleHTML = this.options.content.title.html(this);
          break;
        default:
          this.titleHTML = this.options.content.title.html;
      }

      switch (typeof this.options.content.content.html) {
        case "function":
          this.contentHTML = this.options.content.content.html(this);
          break;
        default:
          this.contentHTML = this.options.content.content.html;
      }
    },
    show() {
      this.model.value = true;
    },
    hide() {
      this.model.value = false;
    },
    returnResult(result) {
      this.emit("xResult", result);
      if (result) {
        this.emit("xYes");
      } else {
        this.hide();
        this.emit("xNo");
      }
    },
  },
  extraOptions: {
    forwardStates: {
      xModel: ["model", false],
    },
    defaultClass: "confirmDialog",
  },
});
